var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;


// Font Resizer-------------------Start
function fontResizer() {
    var perc = parseInt(sw) / 118.9375;
    document.body.style.fontSize = perc + 'px';
}


// Loader-------------------Start
function pageLoader() {
    // document.querySelector("html").classList.add("loadjs");
    setTimeout(function () {
        document.querySelector("html").classList.add("loaded");
    }, 800);

    setTimeout(function () {
        document.querySelector(".loader-first").style.opacity = '0';
        document.querySelector(".loader-first").style.zIndex = '-1';
        document.querySelector(".loader-first").style.transition = '2s';
    }, 300);
}

// Navigation slide Active on a mobile 

// let toggleMenu = document.querySelector('#toggle');
// let htmlEl = document.querySelector("html");

// toggleMenu.addEventListener("click", (event) => {
//     console.log('this', event)
//     let _this = event.target;
//     _this.classList.toggle('active');
//     document.querySelector('.mega-menu').classList.toggle('open')
//     htmlEl.classList.toggle("overflow-hidden");
// })

// accordion
// var accordion = document.getElementsByClassName("faqs__faq-accordion");
// var j;

// for (j = 0; j < accordion.length; j++) {
//     accordion[j].querySelector('h4').addEventListener("click", function () {
//         this.classList.toggle("opened");

//         /* Toggle between hiding and showing the active panel */
//         var panel = this.nextElementSibling;
//         // console.log('checking height', panel.style.maxHeight)
//         if (panel.style.maxHeight) {
//             panel.style.maxHeight = null;
//         } else {
//             panel.style.maxHeight = panel.scrollHeight + "px";
//         }
//     });
// }

// Landscape Mode off 
function landscapeModeOff() {
    if (sh < 450 && sw > 480 && sw < 820) {
        document.getElementById("portrait-warnning").style.display = "flex";
    } else {
        document.getElementById("portrait-warnning").style.display = "none";
    }
}


// Sticky Header----------------Start
function stickyHeader() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}

// Navigation Active-------------------Start
let toggleMenu = document.querySelector("#toggle");

toggleMenu.addEventListener("click", (event) => {
    console.log("this", event);
    let _this = event.target;
    _this.classList.toggle("active");
    document.querySelector(".navigation").classList.toggle("open");
    document.querySelector("body").classList.toggle("overflow-hidden");
    document.html.classList.toggle("overflow-hidden");
    document.querySelector(".search-box").classList.toggle("open");

});

if (sw < 576) {
    document.querySelector('#toggle').addEventListener('click', function (event) {
        var target = document.querySelector("html");
        if (target.classList.contains('overflow-hidden')) {
            target.classList.remove('overflow-hidden');
        } else {
            target.classList.add('overflow-hidden');
        }
    });
}

// Search Open -------------------Start
document.querySelector('.search-btn').addEventListener('click', function (event) {
    event.preventDefault();
    var target = document.querySelector('.search-box');
    if (target.classList.contains('open')) {
        target.classList.remove('open');
    } else {
        target.classList.add('open');
    }
    var target = document.querySelector('.search-btn');
    if (target.classList.contains('open')) {
        target.classList.remove('open');
    } else {
        target.classList.add('open');
    }
    var target = document.querySelector('.nav-button');
    if (target.classList.contains('d-none')) {
        target.classList.remove('d-none');
    } else {
        target.classList.add('d-none');
    }
    // var target = document.querySelector('.language-wrapper');
    // if (target.classList.contains('d-none')) {
    //     target.classList.remove('d-none');
    // } else {
    //     target.classList.add('d-none');
    // }
    var target = document.querySelector('.search-languege-wrapper');
    if (target.classList.contains('w-100')) {
        target.classList.remove('w-100');
    } else {
        target.classList.add('w-100');
    }
    // var target = document.querySelector('body');
    // if (target.classList.contains('overflow-hidden')) {
    //     target.classList.remove('overflow-hidden');
    // } else {
    //     target.classList.add('overflow-hidden');
    // }
});

if (sw < 1200) {
    let mobileToggle = document.querySelector(".mobileToggle,.has-child-menu");

    mobileToggle.addEventListener("click", (event) => {
        document.querySelector(".mobileToggle").classList.toggle("open--menu");
        document.querySelector(".inner-menu").classList.toggle("openMegaMenu");
    });
}

// Language -------------------Start

// document.querySelector('.header').addEventListener('click', function (event) {
//     event.preventDefault();
//     var target = document.querySelector('.language-wrapper');
//     if (target.classList.contains('open')) {
//         target.classList.remove('open');
//     } else {
//         target.classList.add('open');
//     }
// });


// Window on Load-----------------Start
window.onload = function () {
    pageLoader();
    if (sw > 1199) {
        fontResizer();
    }
    // SVG Convert Code
    let svgs = document.querySelectorAll('img.svg-convert');
    svgs.forEach((el) => {
        const imgID = el.getAttribute('id');
        const imgClass = el.getAttribute('class');
        const imgURL = el.getAttribute('src');

        fetch(imgURL)
            .then((response) => response.text())
            .then(data => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, 'text/html');
                let svg = xmlDoc.querySelector('svg');
                if (typeof imgID !== 'undefined') {
                    svg.setAttribute('id', imgID);
                }

                if (typeof imgClass !== 'undefined') {
                    svg.setAttribute('class', imgClass + ' svg-converted');
                }

                svg.removeAttribute('xmlns:a');

                el.parentNode.replaceChild(svg, el);
            })
    });

};

// Window On Resize-----------------Start
window.addEventListener('resize', (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    landscapeModeOff();
    if (sw > 1025) {
        if (sw < 1400 && sw > 1300 && sh > 900) { } else {
            fontResizer();
        }
    }
});

// Window On Scroll-----------------Start
window.addEventListener('scroll', (event) => {
    stickyHeader();
});

// let maintabs = document.querySelector('.masterful-chefs');
let tabscontent = document.querySelectorAll(".individual-pin");
const setActive = (el) => {
    [
        ...el.parentElement.parentElement.parentElement.parentElement.children,
    ].forEach((sib, i) => {
        sib.querySelectorAll(".best-talent__countryList li").forEach((itemsib, i) => {
            itemsib.classList.remove("active");
        });
    });
    tabscontent.forEach((contentsib) => {
        contentsib.classList.remove("active");
    });
    el.classList.add("active");
    let activetabcontentid = el.getAttribute("data-src");
    let tabc = document.getElementById(activetabcontentid);
    if (tabc) {
        tabc.classList.add("active");
    }
};
let spans = [...document.body.querySelectorAll(".best-talent__countryList li")];
spans.forEach((el) => el.addEventListener("click", (e) => setActive(el)));


let innerLink = document.querySelectorAll('.js-inner')
innerLink.forEach(el => el.addEventListener('click', e => functionforscroll(e.target.getAttribute('data-id'))))

function functionforscroll(id) {
    var getMeTo = document.getElementById(id);
    setTimeout(function () {
        getMeTo.scrollIntoView();
    }, 100)
    var uri = window.location.toString();
    if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);
    }
}


// function setNavigation() {
//     let current_location = location.pathname.split("/")[1];
  
//     if (current_location === "") return;
//     let menu_items = document.querySelector("header").getElementsByTagName("a");
//     for (let i = 0, len = menu_items.length; i < len; i++) {
//         if (menu_items[i].getAttribute("href").indexOf(current_location) !== -1) {
//             menu_items[i].classList.add("active");         
//         }
//     }
// }
// setNavigation();

// Home Hero Slide - Hero Slider
let heroslider = document.querySelector(".home-hero__heroSlider");
if (heroslider) {
    var homeHeroSlider = new Swiper(".home-hero__heroSlider", {
        speed: 1500,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        // autoplay: {
        //     delay: 2500,
        // },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + '<span>0' + (index + 1) + "</span>" + "</span>";
            },
        },
        on: {
            init: function (e) {
                const slide = e.slides[0];
                addIFrame(e.slides, 0);
            },
            slideChange: function ({
                realIndex,
                slides
            }) {
                addIFrame(slides, realIndex);
            }
        }
    });
}

function addIFrame(slide, index) {
    slide.forEach(item => {
        $(item).find('.iframeVidz').remove();
    })
    let dsrc1 = $(slide[index]).find(".iframeData").attr("data-src");
    let iFrame1 =
        '<iframe class="iframeVidz" src="' +
        dsrc1 +
        '" style=""></iframe>';
    setTimeout(function () {
        $(slide[index]).find(".iframeData").append(iFrame1);
    }, 500);
}




var blueSecSlider = new Swiper(".blue-sec-slider", {
    slidesPerView: 1,
    speed: 1500,
    spaceBetween: 10,
    // autoplay: {
    //     delay: 2500,
    //     disableOnInteraction: true,
    // },
    navigation: {
        nextEl: ".swiper-navigation-wrapper .swiper-button-next",
        prevEl: ".swiper-navigation-wrapper .swiper-button-prev",
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 5,
            spaceBetween: 92,
        },
        1366: {
            slidesPerView: 5,
            spaceBetween: 92,
        }
    },
});


var thumbCardSlider = new Swiper(".card-slider", {
    slidesPerView: 1,
    speed: 1500,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    freeMode: false,
    breakpoints: {
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },

    },
});
var thumbImgSlider = new Swiper(".thumb-img-slider", {
    slidesPerView: 1,
    speed: 1500,
    watchSlidesProgress: true,
    freeMode: false,
    slideToClickedSlide: true,
    navigation: {
        nextEl: ".thumbSlider-Wrapper .swiper-button-next",
        prevEl: ".thumbSlider-Wrapper .swiper-button-prev",
    },
    thumbs: {
        swiper: thumbCardSlider,
    },
});


var testimonialsSlider = new Swiper(".testimonials-slider", {
    slidesPerView: 1,
    speed: 1500,
    navigation: {
        nextEl: ".testimonials .swiper-button-next",
        prevEl: ".testimonials .swiper-button-prev",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2,

        },
        1200: {
            slidesPerView: 3,

        },

    },
});



var footerSlider = new Swiper(".footer-dog-slider", {
    slidesPerView: 4,
    speed: 1500,
    spaceBetween: 10,
    breakpoints: {
        1024: {
            slidesPerView: 6,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 8,
            spaceBetween: 30,
        },
    },
});




